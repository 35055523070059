import { BanquetLoader } from 'banquet-runtime-modules'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { useCheckDepotFeatureFlag } from '@toasttab/depot-banquet-props'
import { Navigation } from '@local/navigation'
import { useGetNavigationLinks } from './navigationLinks'

export function App() {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  )
}

export function AppRoutes() {
  const dpiEnabled = useCheckDepotFeatureFlag('partners-digi-spi-config')

  const navigationLinks = useGetNavigationLinks()

  return (
    <>
      <Navigation groups={navigationLinks} />
      <Routes>
        {/* 
          route order doesn't matter 
          https://reactrouter.com/en/main/components/routes
          <Routes> looks through all its child routes to find
          the best match and renders that branch of the UI
        */}
        <Route
          path='webhooks/*'
          element={<BanquetLoader name='tpc-manage-webhooks-spa' />}
        />
        {dpiEnabled && (
          <Route
            path='payment-methods/*'
            element={<BanquetLoader name='spi-partner-config-ui' />}
          />
        )}
        <Route //forwards routes to main-spa by default
          path='*'
          element={<BanquetLoader name='tpc-depot-main-spa' />}
        />
      </Routes>
    </>
  )
}
